$breakpoint-alpha: 992px; // adjust to your needs
.order-table {
  margin: 0;
  min-width: 300px; // adjust to your needs
  width: 100%;
  tr {
    // border-top: 1px solid #ddd;
    // border-bottom: 1px solid #ddd;
  }
  
  th {
    display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!   
  }
  
  td {
    display: block; 
    
    &:first-child {
      padding-top: .5em;
    }
    &:last-child {
      padding-bottom: .5em;
    }

    &:before {
      content: attr(data-th)": "; // who knew you could do this? The internet, that's who.
      font-weight: bold;

      // optional stuff to make it look nicer
      width: 6.5em; // magic number :( adjust according to your own content
      display: inline-block;
      // end options
      
      @media (min-width: $breakpoint-alpha) {
        display: none;
      }
    }
  }
  
  th, td {
    text-align: left;
    
    @media (min-width: $breakpoint-alpha) {
      display: table-cell;
      padding: .25em .5em;
      
      &:first-child {
        padding-left: 0;
      }
      
      &:last-child {
        padding-right: 0;
      }
    }

  }
  
  
}


.order-table {
  // background: #34495E;
  // color: #fff;
  // border-radius: .4em;
  overflow: hidden;
  tr {
    // border-color: lighten(#34495E, 10%);
  }
  th, td {
    margin: .5em 1em;
    @media (min-width: $breakpoint-alpha) { 
      padding: 30px 35px !important; 
    }
    @media (max-width: 1280px) {
      padding: 15px 20px !important;
      font-size: 20px;
    }
    @media (max-width: 1160px) {
      padding: 10px 15px !important;
      font-size: 18px;
    }
  }
  th, td:before {
    color: $white;
    background: $table-header;
    font-weight: bold;
    font-size: $font-size;
    @media (max-width: 1280px) {
      font-size: 20px;
    }
    @media (max-width: 1160px) {
      font-size: 18px;
    }
  }
  td {
    background: #eae9e8;
  }
}