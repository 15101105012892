html, body {
  font-size: $font-size;
  font-family: $font;
  background: $white;
}
a {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in;
  color: $link-color;
}

a:link, a:visited, a:hover, a:active {
  outline: none;
  text-decoration: $link-hover-decoration;
  color: $link-hover-color;
}

h1 {
  font-size: $h1;
  text-transform: $h1-transform;
  color: $h1-color;
  font-weight: $h1-weight;
  display: inline-block;
}

h2 {
  font-size: $h2;
  font-weight: $h2-weight;
}


.logo {
  &.full {
    text-align: center;
    img {
      max-width: 100%;
      width: 100%;
    }
  }
}

.inline-block {
  li {
    display: inline-block;
  }
}

.breadcrump {
  li {
    text-transform: uppercase;
    padding-right: 15px;
    margin-right: 13px;
    position: relative;
    float: left;
    &:after {
      color: $black;
      font-weight: 200;
      content: '|';
      position: absolute;
      right: 0;
    }
    span {
      color: $black;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
    a {
      color: $primary;
      &:hover {
        color: $black;
      }
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}
.voir-tout {
  font-size: 26px;
  margin: 40px 0;
  display: inline-block;
}
.content-title {
  background: $table-header;
  height: 50px;
  padding: 10px 30px;
  margin-bottom: 40px;
  h1 {
    font-weight: bold;
    margin: 0;
    font-size: 24px;
    color: $white;
    text-transform: none;
    vertical-align: top;
  }
}




.accordion:hover {
  cursor: pointer;
}
.hidden-row td {
  border: none;
  padding: 0 !important;
  height: 0;
  .first-sub-level {
    border-top: 5px solid #e8b90e;
    width: 100%;
    td {
      padding: 30px 65px !important;
      background: #f3dc86;
    }
  }

}
.table-div {
  display: table;
  width: 100%;
  table-layout: fixed;
  &.top-borders {
    border-top: 2px solid $white;
    @media (max-width: 992px) {
      padding-top: 10px;
      padding-bottom: 10px;
      &.table-details {
        padding: 0;
      }
    }
  }
  @media (max-width: 992px) {
    background: $table-row-closed;
  }
}

.rows {
  display: table-row;
}
.table-header,
.cols {
  display: table-cell;
   &:before {
      // content: attr(data-th)" ";
      // // background: $table-header;
      // // color: $white;
      // // width: 100%;
      // display: inline-block;
      // margin-right: 20px;
      // font-weight: bold;
      // width: 150px;
    }
}

.table-header {
  background: $table-header;
  color: $white;
  padding: 30px 35px;
  &:first-child {
    width: 20%;
  }
  &:nth-child(2) {
    width: 16%;
  }
  &:nth-child(3) {
    width: 18%;
  }
  &:nth-child(4) {
    width: 10%;
  }
  &:nth-child(5) {
    width: 16%;
  }
  &:nth-child(6) {
    width: 13%;
  }
  &:nth-child(7) {
    width: 8%;
  }
  @media (max-width: 992px) {
    display: none;
    padding: 15px 20px;
  }
}
.cols {
  background: $table-row-closed;
  padding: 15px 35px;
  vertical-align: middle;
  line-height: 24px;
  &:nth-child(2) {
    width: 20%;
  }
  &:nth-child(3) {
    width: 16%;
  }
  &:nth-child(5) {
    width: 18%;
  }
  &:nth-child(6) {
    width: 10%;
  }
  &:nth-child(8) {
    width: 16%;
  }
  &:nth-child(9) {
    width: 13%;
  }
  &:nth-child(10) {
    width: 8%;
  }
  &:last-child {
    text-align: center;
  }
  @media (max-width: 992px) {
    display: inline-block;
    float: left;
    width: 33.5%  !important;
    padding: 0px 10px;
    &:nth-child(4) {
      margin-top: -72px;
    }
    &:nth-child(6),
    &:nth-child(2),
    &:nth-child(3) {
      clear: left;
    }
    &:nth-child(6) {
      margin-left: 33%;
    }
    &:nth-child(7) {
      margin-top: -96px;
      margin-left: 66.5%;
    }
    &:nth-child(9) {
      margin-left: 66%;
      margin-top: -24px;
    }
    &:nth-child(10) {
      margin-left: 66%;
    }
    &:nth-child(8) {
      margin-left: 66%;
      margin-top: -48px;
    }
    &:nth-child(6),
    &:nth-child(5) {
      margin-top: -24px;
    }
  }
}

.inside-accordion {
  .cols {
    &:first-child {
      width: 20%;
    }
    &:nth-child(2) {
      width: 16%;
    }
    &:nth-child(3) {
      width: 18%;
    }
    &:nth-child(4) {
      width: 10%;
    }
    &:nth-child(5) {
      width: 16%;
    }
    &:nth-child(6) {
      width: 13%;
    }
    &:nth-child(7) {
      width: 8%;
    }
  }
}
.hidden-table {
  display: table !important;
  &.closed {
    display: none !important;
  }
  .cols {
    padding: 15px 35px;
    background: $table-toggled;
    &:first-child {
      padding-left: 65px;
      @media (max-width: 992px) {
        padding-left: 35px;
      }
    }
    @media (max-width: 992px) {
      width: 33.33% !important;
      &:nth-child(6),
      &:nth-child(2),
      &:nth-child(3) {
        clear: none;
      }
      &:nth-child(6) {
        margin: 0;
      }
      &:nth-child(7) {
        margin: 0;
      }
      &:nth-child(9) {
        margin: 0;
      }
      &:nth-child(10) {
        margin: 0;
      }
      &:nth-child(8) {
        margin: 0;
      }
      &:nth-child(6),
      &:nth-child(5) {
        margin: 0;
      }
    }
  }
 .top-borders  {
   .cols {
     border-top: 2px solid $white;
     @media (max-width: 992px) {
      border: none;
     }
   }
  }
  @media (max-width: 992px) {
    background: $table-toggled;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.hidden-infos {
  width: 100%;
  display: none;
   &.show {
    display: block;
  }
  .full-col {
     padding: 15px 35px 15px 110px;
     @media (max-width: 992px) {
      padding: 10px 20px 10px 40px;
     }
  }
  .light-row {
    background: $table-row-opened;
    display: block;
    p {
      &:last-child {
        margin: 0;
      }
    }
  }
}
.accordion,
.inside-accordion {
  &:hover {
    cursor: pointer;
  }
}



.accordion {
  border-bottom: 5px solid $primary;
  &.fermer {
    border-bottom: none;
  }
}
.table-container {
  @media (max-width: 1366px) {
    font-size: 1.2vw;
  }
  @media (max-width: 992px) {
    font-size: 1.6vw;
    .cols {
      // padding: 0;
      // padding: 15px 20px;
      padding: 0px 15px;
      // width: 33%;
      // background: $white;
      &:last-child {
        text-align: left;
      }
    }
  }
}
.table-header-mobile {
  display: none;
}
@media (max-width: 992px) {
  .table-div {
    &:nth-child(2) {
    .table-header-mobile {
      display: block;
      background: $table-header;
      color: $white;
    }
    }
  }
}

// table tr:nth-of-type(even){background: transparent;}
// table tr:nth-of-type(odd){background: $grey;}