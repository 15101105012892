
/*======== MIXIN ========*/
@mixin opacity($opacity){
  $IE : $opacity*100;
  opacity: $opacity; 
  -moz-opacity: $opacity;
  -webkit-opacity: $opacity;
  -o-opacity: $opacity;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IE+")";
  filter: alpha(opacity=$IE);
  -khtml-opacity: $opacity;
}

@mixin button($font_size, $color, $text_color, $radius, $bordercolor) {
  cursor: pointer;
  font-size: $font_size;
  color: $text_color;
  background: $color;
  border-radius: $radius;
  border-color: $bordercolor;
  transition: all 0.1s ease-in;
  &:active,
  &:active:hover,
  &:focus,
  &.active,
  &:active:focus,
  &.focus:active,
  &.active:hover,
  &.active.focus,
  &.active:focus,
  &.focus,
  &:hover  {
    background: $button_hover;
    border-color: $button_hover;
  }
}

@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  @include border-radius($border-radius);
}
@mixin button-variant($color, $background, $fontfamily) {
  color: $color;
  background-color: $background;
  font-family: $fontfamily;
}
@mixin transform($val...){
  transform: $val;
  -webkit-transform: $val;
  -moz-transform: $val;
  -o-transform: $val;
  -ms-transform: $val;
}
@mixin transition($val...){
  -webkit-transition: $val;
  -moz-transition: $val;
  -o-transition: $val;
  transition: $val;
  -ms-transition: $val; 
}

@mixin rotate($degree) {
  -ms-transform: rotate($degree);
  -webkit-transform: rotate($degree);
  -moz-transform: rotate($degree);
  -o-transform: rotate($degree);
  transform: rotate($degree);
  display: inline-block;
}


/*********************
ICONS
*********************/
@mixin icon($position: before, $icon: false, $styles: true) {
    @if $position == both {
        $position: 'before, &:after';
    }
    // Either a :before or :after pseudo-element, or both, defaulting to :before
    &:#{$position} {
        @if $icon {
            // A particular icon has been specified
            content: "#{map-get($icons, $icon)}";
        }
        @if $styles {
            // Supportive icon styles required
          font-family: $font-icon;
          src:  url('../../fonts/icomoon.eot?7ihb11');
          src:  url('../../fonts/icomoon.eot?7ihb11#iefix') format('embedded-opentype'),
          url('../../fonts/icomoon.ttf?7ihb11') format('truetype'),
          url('../../fonts/icomoon.woff?7ihb11') format('woff'),
          url('../../fonts/icomoon.svg?7ihb11#icomoon') format('svg');
          font-weight: normal;
          font-style: normal;
        }
        // Include any extra rules supplied for the pseudo-element
        @content;
    }
}

