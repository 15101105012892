input:not([type="submit"]),
input,
optgroup,
select  {
  padding: 15px 30px;
  transition: all 0.30s ease-in-out;
  color:  $text-color;
  background: $body-bg;
  border: 1px solid $border-color;
  font-weight: 600;
  box-shadow : none;
  font-size: $font-size-sm;
  text-transform: uppercase;
  width: 100%;
  display: inline-block;
  max-width: 330px;
  height: 45px;
  @media (max-width: 768px) {
   &.search-field {
    max-width: 240px;
    padding: 10px 20px;
    font-size: 13px;
   }
  }
  @media (max-width: 420px) {
    max-width: 100%;
  }
  &:active,
  &:focus {
    background: $body-bg;
    box-shadow: none;
    border: 1px solid $primary;
    &::-webkit-input-placeholder {
      color: $primary;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: $primary;
      opacity: 1;
      border: 1px solid $primary;
    }
    &::-moz-placeholder {
      color: $primary;
      opacity: 1;
      border: 1px solid $primary;
    }
    &:-ms-input-placeholder {
      color: $primary;
      opacity: 1;
      border: 1px solid $primary;
    }
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  outline: none;
  -webkit-appearance:none;
  &::-webkit-input-placeholder {
      color: $text-color;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: $text-color;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: $text-color;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $text-color;
      opacity: 1;
    }
}
input[type="button"],
.btn-primary {
  @include button($font-size-md, $primary, $white, 0, $primary);
  padding: 15px 40px;
  &.icon-plus,
  &.search {
    padding: 5px 10px 2px;
    display: inline-block;
    @media (max-width: 768px) {
      padding: 6px 10px 4px;
    }
  }
}
.access-form {
  input:not([type="submit"]),
  input,
  optgroup,
  select  {
    font-size: $font-size-md;
    text-transform: uppercase;
    text-align: center;
    max-width: 475px;
    height: 60px;
    display: block;
    margin: 0 auto 10px;
    @media (max-width: 640px) {
      max-width: 100%;
      font-size: $font-size-sm;
      padding: 15px 15px;
    }
  }
  .btn-primary {
    height: 60px;
    max-width: 315px;
    width: 100%;
    @media (max-width: 640px) {
      max-width: 90%;
      font-size: $font-size-sm;
    }
  }
}
label {
  font-weight: normal;
}

.styled-select {
  height: 45px;
  width: 100%;
  display: inline-block;
  max-width: 330px;
  position: relative;
  display: block;
  box-shadow: none;
}

select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 30px;
  border: 1px solid $border-color;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.styled-select:after {
  color: $white;
  font-size: 30px;
  position: absolute; right: -1px; top: -1px;
  background: $primary;
  z-index: 1;
  text-align: center;
  width: 55px;
  height: 46px;
  pointer-events: none;
  box-sizing: border-box;
}
.commande-form {
  padding: 0 0 40px 0;
  display: block;
  width: 100%;
  max-width: 675px;
  @media (max-width: 715px) {
    max-width: 100%;
  }
  input,
  label {
    display: inline-block;
  }
}
textarea {
  padding: 15px 30px;
  transition: all 0.30s ease-in-out;
  color:  $text-color;
  background: $body-bg;
  border: 1px solid $border-color;
  font-weight: 600;
  box-shadow : none;
  font-size: $font-size-sm;
  text-transform: uppercase;
  width: 100%;
  display: inline-block;
  max-width: 330px;
  height: 90px;
  @media (max-width: 715px) {
    max-width: 100%;
  }
}
.form-wrap {
  display: block;
  padding: 0 40px;
  margin-bottom: 15px;
  label {
    width: 180px;
    @media (max-width: 715px) {
      display: block;
      width: auto;
    }
  }
  .styled-select,
  select,
  textarea,
  input {
    max-width: 405px;
    width: 100%;
  }
  &.small-boxes {
    input.small {
      max-width: 60px;
      margin-right: 16px;
      padding: 15px;
      @media (max-width: 370px) {
        margin-right: 5px;
        max-width: 55px;
      }
    }
    input.medium {
      max-width: 85px;
      padding: 15px;
      @media (max-width: 370px) {
        max-width: 75px;
      }
    }
    .small-label {
      width: auto;
      margin: 0 10px 0 0;
      @media (max-width: 715px) {
        display: block;
        margin-top: 15px;
      }
    }
  }
  &.label-top {
    label {
      vertical-align: top;
    }
  }
  .btn-primary {
    display: inline-block;
    width: auto;
    max-width: auto;
    height: 60px;
    font-size: 22px;
    font-weight: 500;
    @media (max-width: 500px) {
      display: block;
      width: auto;
      margin: 0 auto 15px;
    }
  }
}






input[type="radio"],
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  opacity: 0;
  position: absolute;
}
label,
input[type="radio"] + span,
input[type="radio"] + span::before,
label,
input[type="checkbox"] + span,
input[type="checkbox"] + span::before {
  display: inline-block;
  vertical-align: middle;
}
label *,
label * {
  cursor: pointer;
}
label:hover span::before,
label:hover span::before {
  box-shadow: none;
}
label:hover span,
label:hover span {
  color: $black;
}
input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  margin: 0;
  border: 1px solid $white;
  background: $white;
  line-height: 32px;
  text-align: center;
  border-radius: 50% !important;
  @media (max-width: 992px) {
    width: 25px;
    height: 25px;
    line-height: 22px;
  }
}

input[type="radio"]:checked + span::before,
input[type="checkbox"]:checked + span::before {
  color: $white;
  background: $primary;
  border-color: $primary;
}
input[type="radio"]:disabled + span,
input[type="checkbox"]:disabled + span {
  cursor: default;
  opacity: .4;
}
input[type="radio"] + span::before {
  line-height: 14px;
}
input[type="checkbox"] + span::before {
  border-radius: 0;
}
// input[type="radio"]:checked + span::before {
//   content: "\2022";
//   font-size: 32px;
// }
input[type="checkbox"]:checked + span::before {
  content: "\2714";
  font-weight: 200;
  font-size: 30px;
  color: $white;
  @media (max-width: 992px) {
    font-size: 20px;
  }
}