/** 
 **************************************************************** **/
html {
  overflow-x: hidden;
  overflow-y: auto;
}

html, body {
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
}
body.overflow-hidden {
  height: 100% !important;
  overflow: hidden;
}
#topHead {
  min-height: $banner-height;
  background-image: $logo-img;
  background-size: cover;
  background-position: center center;
}
footer {
  height: $footer-height;
  background: $footer-color;
}
.navBar {
  height: $header-height;
  background: $header-color;
  position: relative;
}
.access-form {
  margin: $margin-lg 0;
  text-align: center;
}
.navBar {
  h1 {
    position: relative;
    left: 50px;
  }
}
.search-box,
.breadcrump {
  display: inline-block;
}
.breadcrump  {
  width: 40%;
}
.search-box {
  width: 60%;
}

.bottom-header {
  padding: 40px 0;
}
header {
  .logo {
    margin: 40px 0;
  }
}