.hamburger {
  z-index: 99999999999;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 20px;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  padding: 0;
  @include transform(translate(0, -50%));

  span {
    position: relative;
    display: block;
    width: $hamburger_width;
    height: 3px;
    margin-top: 0 ;
    background: $hamburguer_color;
    @include transition(all $hamburger_transition);

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      left: 0;
      background: $hamburguer_color;
      position: absolute;
      @include transition(all $hamburger_transition);
    }
    &:before { top: -#{$hamburger_margin}; }
    &:after { bottom: -#{$hamburger_margin}; }
  }
  &.close {
  opacity: 1;
    span {
      @include transform(rotate(0deg));
      &:before,
      &:after {
        width: 10px;
      }
      &:before {
        top: -4px;
        @include transform(rotate(-45deg));
      }
      &:after {
        bottom: -4px;
        @include transform(rotate(45deg));
      }
    }
  }
}