//
// ICONS
// --------------------------------------------------
@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?7ihb11');
  src:  url('../../fonts/icomoon.eot?7ihb11#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon.ttf?7ihb11') format('truetype'),
  url('../../fonts/icomoon.woff?7ihb11') format('woff'),
  url('../../fonts/icomoon.svg?7ihb11#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  &:before {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

$icons: (
  accordion: "\e908",
  plus: "\e904",
  down: "\e909",
  close: "\e905",
  check: "\e906",
  logout: "\e903",
  search: "\e907",
  welcome: "\e901",
  settings: "\e900",
  help: "\e902"
);

// Setup a class name for each icon
@each $name, $char in $icons {
    .icon-#{$name} {
      content: $char;
      color: $white;
      &:before {
        font-size: 30px;
      }
      @media (max-width: 992px) {
        span {
          text-indent: -999em;
          display: block;
        }
      }
    }
}
.icon-accordion {
    @include icon(before, accordion){}
}
.icon-plus {
    @include icon(before, plus){}
}
.icon-down {
    @include icon(after, down){}
}
.icon-close {
    @include icon(before, close){}
}
input[type="checkbox"]:checked + span,
input[type="radio"]:checked + span,
.icon-check {
    @include icon(before, check){}
}
.icon-logout {
    @include icon(before, logout){}
}
.icon-search {
    @include icon(before, search){}
}
.icon-welcome {
    @include icon(before, welcome){}
}
.icon-settings {
    @include icon(before, settings){}
}
.icon-help {
    @include icon(before, help){}
}