/* COLOR */
$font-icon: "icomoon";

//== Colors
//
//## Gray and brand colors for use across Bootstrap.
$black:                   #231f20;
$yellow:                  #e8b90e;
$grey:                    #6a6159;


$red:                     #ff5252;
$white:                   #ffffff;

$primary:                 $yellow;
$text-color:              $grey;
$border-color:            $text-color;
$button-hover:            $grey;
$table-header:            #8f8983;
$table-row-closed:        #eae9e8;
$table-toggled:           #f3dc86;
$table-row-opened:        #f7e7ab;
//** Background color for `<body>`.
$body-bg:                 $white;
//** Global text color on `<body>`.
$text-color:              $grey;

//** Global textual link color.
$link-color:              $grey;
$link-hover-color:        $primary;
$link-hover-decoration:   none;


/* FONT */
$font                     :'Muli', sans-serif;

/* HEADING */
$h1:                       26px;
$h2:                       24px;
$h3:                       22px;
$h4:                       20px;
$h5:                       18px;
$h6:                       16px;

$h1-color:                 $white;
$h1-weight:                normal;
$h1-transform:             uppercase;

$h2-color:                 $grey;
$h2-weight:                bold;
$h2-transform:             none;

/* FONT */
//google font import
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700');

//== Typography
//
$font-size:                24px;
$font-size-md:             22px;
$font-size-sm:             16px;
// $font-icon:                font-family: 'icomoon';

/*hamburger*/
$toggle-bg:                #8f8983;
$toggle-border:            $white;
$toggle-items:             $white;
$toggle-transform:         uppercase;
$toggle-font-size:         1em;
$toggle-weight:            300;
$toggle-padding:           1em;
$toggle-hover:             $white;
$toggle-decoration:        none;

/*Menu desktop*/
$menu-color:               $white;
$menu-color-active:        $primary;
$margin-top-menu:          10px;
$margin-left-menu:         20px;
$menu-font-size:           11px;
$menu-font-weight:         600;
$menu-font-transform:      uppercase;
$menu-decoration:          none;

$hamburguer-color:         $white;
$hamburger-margin:         7px;
$hamburger-width:          25px;
$hamburger-transition:     0.2s;

$logo-img:                 url(../../images/avanti-header.jpg);


$banner-height:            45vh;
$footer-color:             #e3e2e0;
$header-color:             #8f8983;
$header-height:            65px;
$footer-height:            50px;

$margin-lg:                100px;
