.main-menu {
	display: inline-block;
	li {
		margin-top: $margin-top-menu;
		float: left;
		margin-left: $margin-left-menu;
		a {
			color: $menu-color;
			font-size: $menu-font-size;
			font-weight: $menu-font-weight;
			text-transform: $menu-font-transform;
			padding: 8px 0 0 40px;
			position: relative;
			font-family: $font;
			.desktop &:hover {
				color: $menu-color-active;
				text-decoration: $menu-decoration;
			}
			&:before {
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		&.current-menu-item  a {
			color: $menu-color-active;
			padding-bottom: 5px;
		}
	}
}

.menu-toggle {
	display: block;
	background: $toggle-bg;
	position: fixed;
	overflow-y: auto;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	width: 350px; height: 100%;
	top: $header-height; left: -350px;
	z-index: 1000;
	ul {
		position: relative;
		top: 0; left: 0;
		margin: 0;
		padding: 0;
	}
	li {
		text-align: center;
		a {
			display: block;
			color: $toggle-items;
			font-size: $toggle-font-size;
			font-weight: $toggle-weight;
			padding: $toggle-padding;
			border-bottom: 1px solid $toggle-border;
			text-transform: $toggle-transform;
			&:hover {
				text-decoration: $toggle-decoration;
				background: $primary;
			}
			&:active {
				background: #fff;
				color: $primary;
			}
		}
		&:last-child {
			a {
					border-bottom: none;
			}
		}
	}
	&.open {
		left: 0px;
	}
}